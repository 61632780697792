<template>
  <div class="page page-login">
    <div class="page-content">
      <div class="login-type-switch">
        <template v-for="(v,i) in loginTypeList">
          <span v-if="i>0" class="login-type-btn-gap" :key="`1_${i}`"></span>
          <span :class="['login-type-btn', {active: loginTypeActiveId===v.id}]"
            @click="loginTypeActiveId=v.id" :key="`2_${i}`"
          >{{v.label}}</span>
        </template>
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px">
        <template v-if="loginTypeActiveId===0">
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="ruleForm.phone" placeholder="请输入手机号码"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="ruleForm.password" type="password" placeholder="请用6-18位数字和字母组合"></el-input>
          </el-form-item>
          <!-- <el-form-item class="text-right">
            <el-button type="text" @click="gotoChangepassword">忘记密码</el-button>
          </el-form-item> -->
        </template>
        <template v-if="loginTypeActiveId===1">
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="ruleForm.phone" placeholder="请输入手机号码"></el-input>
          </el-form-item>
          <el-form-item label="短信验证码" prop="code">
            <el-row type="flex" :gutter="4">
              <el-col :span="17"><el-input v-model="ruleForm.code" placeholder="请输入短信验证码"></el-input></el-col>
              <el-col :span="7">
                <el-button type="primary" style="width:100%" @click="getCode" :disabled="codeCount>0">
                  {{codeCount > 0 ? `${codeCount}秒后可重试`: '获取验证码'}}
                </el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </template>
        <el-form-item>
          <el-button class="submit-btn" type="primary" @click="submitForm">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapActions} from 'vuex'
import publicUrl from '../../assets/js/interface'

export default {
  name: 'page-login',
  data () {
    return {
      codeCount: 0,
      codeTimer: null,
      loginTypeList: [
        {id: 0, label: '密码登录', name: 'password'},
        {id: 1, label: '验证码登录', name: 'verification_code'}
      ],
      loginTypeActiveId: 0,
      ruleForm: {
        phone: '',
        password: '',
        code: ''
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    ...mapActions(['companyManagerStatus']),
    getCode () {
      if (!this.ruleForm.phone) {
        this.$message.warning('请输入手机号码')
        return
      }
      this.loadingCount++
      this.codeCount = 30
      this.codeTimer = setInterval(() => {
        this.codeCount--
        if (this.codeCount <= 0) {
          clearInterval(this.codeTimer)
          this.codeCount = 0
          this.codeTimer = null
        }
      }, 1000)
      this.$ajaxJson.post(publicUrl.getLoginCode, {
        phone: this.ruleForm.phone
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success(res.data.msg || '验证码已发出')
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(err => {
        this.$message.error('验证码获取失败')
        this.$ajaxError(err)
      }).finally(() => {
        this.loadingCount--
      })
    },
    gotoChangepassword () {
      this.$router.push({name: 'changepassword'})
    },
    submitForm () {
      this.$refs.ruleForm && this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loadingCount++
          this.$ajaxJson.post(publicUrl.login, {
            account: this.ruleForm.phone,
            password: this.ruleForm.password,
            code: this.ruleForm.code,
            loginType: this.loginTypeList[this.loginTypeActiveId].name
          }).then(res => {
            if (res.data.code === 0) {
              let _data = res.data.data || {}
              localStorage.setItem('token', _data.token)
              this.setUserInfo(_data)
              this.loadingCount++
              this.companyManagerStatus({
                vm: this.$root,
                token: _data.token,
                ElMessage: this.$message
              }).then(() => {
                this.$router.push({name: 'home'})
              }).finally(() => {
                this.loadingCount--
              })
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(err => {
            this.$ajaxError(err)
          }).finally(() => {
            this.loadingCount--
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page-login {
  position: relative;
  .page-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    min-height: 320px;
  }
  .login-type-switch {
    height: 60px;
    padding-left: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    .login-type-btn {
      width: 5.5em;
      text-align: center;
      text-shadow: 0 0 1px #fff;
      margin: 0 10px;
      border-bottom: 1px solid transparent;
      cursor: pointer;
      &.active {
        color: #409eff;
        border-color: #409eff;
      }
    }
    .login-type-btn-gap {
      width: 1px;
      height: 15px;
      background-color: #333;
    }
  }
  .submit-btn {
    width: 100%;
  }
}
</style>
